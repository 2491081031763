import { groq } from 'next-sanity'
import { z } from 'zod'

import { pageSEOFields, PageSEOFieldsExtendedSchema } from '../components/seo/pageSEOFields'
import {
  pageBuilderComponentsData,
  PageBuilderComponentsDataSchema,
  pageBuilderDataTypes,
} from './pageCommonQueries/pageBuilderComponentsData'

export const homePageStructure = groq`
*[_type == "home"][0] {
  homeContent[] {
    ${pageBuilderDataTypes}
  },
}`

export const getHomePageBase = (content: string) => groq`
*[_type == "home"][0] {
  _id,
  _type,
  title,
  locations[]->,
  "seo": {
    title,
    "titleTemplate": '%s',
    'canonical': '',
    ...seo {
      ${pageSEOFields}
    }
  },
  ${content}
}`

export const homePage = groq`
*[_type == "home"][0] {
  _id,
  _type,
  title,
  homeContent[] {
    ${pageBuilderComponentsData}
  },
  locations[]->,
  "seo": {
    title,
    "titleTemplate": '%s',
    'canonical': '',
    ...seo {
      ${pageSEOFields}
    }
  },
}`

export const HomePageSchema = z.object({
  _id: z.string(),
  _type: z.literal('home'),
  title: z.string(),
  homeContent: z.nullable(z.array(PageBuilderComponentsDataSchema)),
  locations: z.array(z.any()),
  seo: PageSEOFieldsExtendedSchema,
})
